
export default function slideToClosest(
speed = this.params.speed,
runCallbacks = true,
internal,
threshold = 0.5,
){
const swiper = this;
let index = swiper.activeIndex;
const skip = Math.min(swiper.params.slidesPerGroupSkip, index);
const snapIndex = skip + Math.floor((index - skip) / swiper.params.slidesPerGroup);

const translate = swiper.rtlTranslate ? swiper.translate:-swiper.translate;

if (translate >= swiper.snapGrid[snapIndex]){
// The current translate is on or after the current snap index, so the choice
// is between the current index and the one after it.
const currentSnap = swiper.snapGrid[snapIndex];
const nextSnap = swiper.snapGrid[snapIndex + 1];
if (translate - currentSnap > (nextSnap - currentSnap) * threshold){
index += swiper.params.slidesPerGroup;
}
} else{
// The current translate is before the current snap index, so the choice
// is between the current index and the one before it.
const prevSnap = swiper.snapGrid[snapIndex - 1];
const currentSnap = swiper.snapGrid[snapIndex];
if (translate - prevSnap <= (currentSnap - prevSnap) * threshold){
index -= swiper.params.slidesPerGroup;
}
}
index = Math.max(index, 0);
index = Math.min(index, swiper.slidesGrid.length - 1);

return swiper.slideTo(index, speed, runCallbacks, internal);
}
