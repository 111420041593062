export default function Grid({ swiper, extendParams}){
extendParams({
grid:{
rows:1,
fill:'column',
},
});

let slidesNumberEvenToRows;
let slidesPerRow;
let numFullColumns;

const initSlides = (slidesLength) =>{
const{ slidesPerView} = swiper.params;
const{ rows, fill} = swiper.params.grid;
slidesPerRow = slidesNumberEvenToRows / rows;
numFullColumns = Math.floor(slidesLength / rows);
if (Math.floor(slidesLength / rows) === slidesLength / rows){
slidesNumberEvenToRows = slidesLength;
} else{
slidesNumberEvenToRows = Math.ceil(slidesLength / rows) * rows;
}
if (slidesPerView !== 'auto' && fill === 'row'){
slidesNumberEvenToRows = Math.max(slidesNumberEvenToRows, slidesPerView * rows);
}
};

const updateSlide = (i, slide, slidesLength, getDirectionLabel) =>{
const{ slidesPerGroup, spaceBetween} = swiper.params;
const{ rows, fill} = swiper.params.grid;
// Set slides order
let newSlideOrderIndex;
let column;
let row;
if (fill === 'row' && slidesPerGroup > 1){
const groupIndex = Math.floor(i / (slidesPerGroup * rows));
const slideIndexInGroup = i - rows * slidesPerGroup * groupIndex;
const columnsInGroup =
groupIndex === 0
? slidesPerGroup
:Math.min(
Math.ceil((slidesLength - groupIndex * rows * slidesPerGroup) / rows),
slidesPerGroup,
);
row = Math.floor(slideIndexInGroup / columnsInGroup);
column = slideIndexInGroup - row * columnsInGroup + groupIndex * slidesPerGroup;

newSlideOrderIndex = column + (row * slidesNumberEvenToRows) / rows;
slide.css({
'-webkit-order':newSlideOrderIndex,
order:newSlideOrderIndex,
});
} else if (fill === 'column'){
column = Math.floor(i / rows);
row = i - column * rows;
if (column > numFullColumns || (column === numFullColumns && row === rows - 1)){
row += 1;
if (row >= rows){
row = 0;
column += 1;
}
}
} else{
row = Math.floor(i / slidesPerRow);
column = i - row * slidesPerRow;
}
slide.css(
getDirectionLabel('margin-top'),
row !== 0 ? spaceBetween && `${spaceBetween}px`:'',
);
};

const updateWrapperSize = (slideSize, snapGrid, getDirectionLabel) =>{
const{ spaceBetween, centeredSlides, roundLengths} = swiper.params;
const{ rows} = swiper.params.grid;
swiper.virtualSize = (slideSize + spaceBetween) * slidesNumberEvenToRows;
swiper.virtualSize = Math.ceil(swiper.virtualSize / rows) - spaceBetween;
swiper.$wrapperEl.css({
[getDirectionLabel('width')]:`${swiper.virtualSize + spaceBetween}px`,
});
if (centeredSlides){
snapGrid.splice(0, snapGrid.length);
const newSlidesGrid = [];
for (let i = 0; i < snapGrid.length; i += 1){
let slidesGridItem = snapGrid[i];
if (roundLengths) slidesGridItem = Math.floor(slidesGridItem);
if (snapGrid[i] < swiper.virtualSize + snapGrid[0]) newSlidesGrid.push(slidesGridItem);
}
snapGrid.push(...newSlidesGrid);
}
};

swiper.grid ={
initSlides,
updateSlide,
updateWrapperSize,
};
}
