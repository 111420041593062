import $ from '../../shared/dom.js';
import effectInit from '../../shared/effect-init.js';

export default function EffectCube({ swiper, extendParams, on}){
extendParams({
cubeEffect:{
slideShadows:true,
shadow:true,
shadowOffset:20,
shadowScale:0.94,
},
});

const createSlideShadows = ($slideEl, progress, isHorizontal) =>{
let shadowBefore = isHorizontal
? $slideEl.find('.swiper-slide-shadow-left')
:$slideEl.find('.swiper-slide-shadow-top');
let shadowAfter = isHorizontal
? $slideEl.find('.swiper-slide-shadow-right')
:$slideEl.find('.swiper-slide-shadow-bottom');
if (shadowBefore.length === 0){
shadowBefore = $(`<div class="swiper-slide-shadow-${isHorizontal ? 'left':'top'}"></div>`);
$slideEl.append(shadowBefore);
}
if (shadowAfter.length === 0){
shadowAfter = $(
`<div class="swiper-slide-shadow-${isHorizontal ? 'right':'bottom'}"></div>`,
);
$slideEl.append(shadowAfter);
}
if (shadowBefore.length) shadowBefore[0].style.opacity = Math.max(-progress, 0);
if (shadowAfter.length) shadowAfter[0].style.opacity = Math.max(progress, 0);
};

const recreateShadows = () =>{
// create new ones
const isHorizontal = swiper.isHorizontal();
swiper.slides.each((slideEl) =>{
const progress = Math.max(Math.min(slideEl.progress, 1), -1);
createSlideShadows($(slideEl), progress, isHorizontal);
});
};

const setTranslate = () =>{
const{
$el,
$wrapperEl,
slides,
width:swiperWidth,
height:swiperHeight,
rtlTranslate:rtl,
size:swiperSize,
browser,
} = swiper;
const params = swiper.params.cubeEffect;
const isHorizontal = swiper.isHorizontal();
const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
let wrapperRotate = 0;
let $cubeShadowEl;
if (params.shadow){
if (isHorizontal){
$cubeShadowEl = $wrapperEl.find('.swiper-cube-shadow');
if ($cubeShadowEl.length === 0){
$cubeShadowEl = $('<div class="swiper-cube-shadow"></div>');
$wrapperEl.append($cubeShadowEl);
}
$cubeShadowEl.css({ height:`${swiperWidth}px`});
} else{
$cubeShadowEl = $el.find('.swiper-cube-shadow');
if ($cubeShadowEl.length === 0){
$cubeShadowEl = $('<div class="swiper-cube-shadow"></div>');
$el.append($cubeShadowEl);
}
}
}
for (let i = 0; i < slides.length; i += 1){
const $slideEl = slides.eq(i);
let slideIndex = i;
if (isVirtual){
slideIndex = parseInt($slideEl.attr('data-swiper-slide-index'), 10);
}
let slideAngle = slideIndex * 90;
let round = Math.floor(slideAngle / 360);
if (rtl){
slideAngle = -slideAngle;
round = Math.floor(-slideAngle / 360);
}
const progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
let tx = 0;
let ty = 0;
let tz = 0;
if (slideIndex % 4 === 0){
tx = -round * 4 * swiperSize;
tz = 0;
} else if ((slideIndex - 1) % 4 === 0){
tx = 0;
tz = -round * 4 * swiperSize;
} else if ((slideIndex - 2) % 4 === 0){
tx = swiperSize + round * 4 * swiperSize;
tz = swiperSize;
} else if ((slideIndex - 3) % 4 === 0){
tx = -swiperSize;
tz = 3 * swiperSize + swiperSize * 4 * round;
}
if (rtl){
tx = -tx;
}

if (!isHorizontal){
ty = tx;
tx = 0;
}

const transform = `rotateX(${isHorizontal ? 0:-slideAngle}deg) rotateY(${
isHorizontal ? slideAngle:0
}deg) translate3d(${tx}px, ${ty}px, ${tz}px)`;
if (progress <= 1 && progress > -1){
wrapperRotate = slideIndex * 90 + progress * 90;
if (rtl) wrapperRotate = -slideIndex * 90 - progress * 90;
}
$slideEl.transform(transform);
if (params.slideShadows){
createSlideShadows($slideEl, progress, isHorizontal);
}
}
$wrapperEl.css({
'-webkit-transform-origin':`50% 50% -${swiperSize / 2}px`,
'transform-origin':`50% 50% -${swiperSize / 2}px`,
});

if (params.shadow){
if (isHorizontal){
$cubeShadowEl.transform(
`translate3d(0px, ${swiperWidth / 2 + params.shadowOffset}px, ${
-swiperWidth / 2
}px) rotateX(90deg) rotateZ(0deg) scale(${params.shadowScale})`,
);
} else{
const shadowAngle = Math.abs(wrapperRotate) - Math.floor(Math.abs(wrapperRotate) / 90) * 90;
const multiplier =
1.5 -
(Math.sin((shadowAngle * 2 * Math.PI) / 360) / 2 +
Math.cos((shadowAngle * 2 * Math.PI) / 360) / 2);
const scale1 = params.shadowScale;
const scale2 = params.shadowScale / multiplier;
const offset = params.shadowOffset;
$cubeShadowEl.transform(
`scale3d(${scale1}, 1, ${scale2}) translate3d(0px, ${swiperHeight / 2 + offset}px, ${
-swiperHeight / 2 / scale2
}px) rotateX(-90deg)`,
);
}
}
const zFactor = browser.isSafari || browser.isWebView ? -swiperSize / 2:0;
$wrapperEl.transform(
`translate3d(0px,0,${zFactor}px) rotateX(${
swiper.isHorizontal() ? 0:wrapperRotate
}deg) rotateY(${swiper.isHorizontal() ? -wrapperRotate:0}deg)`,
);
$wrapperEl[0].style.setProperty('--swiper-cube-translate-z', `${zFactor}px`);
};
const setTransition = (duration) =>{
const{ $el, slides} = swiper;
slides
.transition(duration)
.find(
'.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left',
)
.transition(duration);
if (swiper.params.cubeEffect.shadow && !swiper.isHorizontal()){
$el.find('.swiper-cube-shadow').transition(duration);
}
};

effectInit({
effect:'cube',
swiper,
on,
setTranslate,
setTransition,
recreateShadows,
getEffectParams:() => swiper.params.cubeEffect,
perspective:() => true,
overwriteParams:() => ({
slidesPerView:1,
slidesPerGroup:1,
watchSlidesProgress:true,
resistanceRatio:0,
spaceBetween:0,
centeredSlides:false,
virtualTranslate:true,
}),
});
}
