export default function transitionEmit({ swiper, runCallbacks, direction, step}){
const{ activeIndex, previousIndex} = swiper;
let dir = direction;
if (!dir){
if (activeIndex > previousIndex) dir = 'next';
else if (activeIndex < previousIndex) dir = 'prev';
else dir = 'reset';
}

swiper.emit(`transition${step}`);

if (runCallbacks && activeIndex !== previousIndex){
if (dir === 'reset'){
swiper.emit(`slideResetTransition${step}`);
return;
}
swiper.emit(`slideChangeTransition${step}`);
if (dir === 'next'){
swiper.emit(`slideNextTransition${step}`);
} else{
swiper.emit(`slidePrevTransition${step}`);
}
}
}
