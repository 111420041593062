import $ from '../../shared/dom.js';

export default function updateAutoHeight(speed){
const swiper = this;
const activeSlides = [];
const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
let newHeight = 0;
let i;
if (typeof speed === 'number'){
swiper.setTransition(speed);
} else if (speed === true){
swiper.setTransition(swiper.params.speed);
}

const getSlideByIndex = (index) =>{
if (isVirtual){
return swiper.slides.filter(
(el) => parseInt(el.getAttribute('data-swiper-slide-index'), 10) === index,
)[0];
}
return swiper.slides.eq(index)[0];
};
// Find slides currently in view
if (swiper.params.slidesPerView !== 'auto' && swiper.params.slidesPerView > 1){
if (swiper.params.centeredSlides){
(swiper.visibleSlides || $([])).each((slide) =>{
activeSlides.push(slide);
});
} else{
for (i = 0; i < Math.ceil(swiper.params.slidesPerView); i += 1){
const index = swiper.activeIndex + i;
if (index > swiper.slides.length && !isVirtual) break;
activeSlides.push(getSlideByIndex(index));
}
}
} else{
activeSlides.push(getSlideByIndex(swiper.activeIndex));
}

// Find new height from highest slide in view
for (i = 0; i < activeSlides.length; i += 1){
if (typeof activeSlides[i] !== 'undefined'){
const height = activeSlides[i].offsetHeight;
newHeight = height > newHeight ? height:newHeight;
}
}

// Update Height
if (newHeight || newHeight === 0) swiper.$wrapperEl.css('height', `${newHeight}px`);
}
