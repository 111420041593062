export default function slideToLoop(
index = 0,
speed = this.params.speed,
runCallbacks = true,
internal,
){
if (typeof index === 'string'){

const indexAsNumber = parseInt(index, 10);


const isValidNumber = isFinite(indexAsNumber);

if (!isValidNumber){
throw new Error(
`The passed-in 'index' (string) couldn't be converted to 'number'. [${index}] given.`,
);
}

// Knowing that the converted `index` is a valid number,
// we can update the original argument's value.
index = indexAsNumber;
}

const swiper = this;
let newIndex = index;
if (swiper.params.loop){
newIndex += swiper.loopedSlides;
}

return swiper.slideTo(newIndex, speed, runCallbacks, internal);
}
