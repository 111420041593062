import{ now, nextTick} from '../../shared/utils.js';

export default function onTouchEnd(event){
const swiper = this;
const data = swiper.touchEventsData;

const{ params, touches, rtlTranslate:rtl, slidesGrid, enabled} = swiper;
if (!enabled) return;
let e = event;
if (e.originalEvent) e = e.originalEvent;
if (data.allowTouchCallbacks){
swiper.emit('touchEnd', e);
}
data.allowTouchCallbacks = false;
if (!data.isTouched){
if (data.isMoved && params.grabCursor){
swiper.setGrabCursor(false);
}
data.isMoved = false;
data.startMoving = false;
return;
}
// Return Grab Cursor
if (
params.grabCursor &&
data.isMoved &&
data.isTouched &&
(swiper.allowSlideNext === true || swiper.allowSlidePrev === true)
){
swiper.setGrabCursor(false);
}

// Time diff
const touchEndTime = now();
const timeDiff = touchEndTime - data.touchStartTime;

// Tap, doubleTap, Click
if (swiper.allowClick){
const pathTree = e.path || (e.composedPath && e.composedPath());
swiper.updateClickedSlide((pathTree && pathTree[0]) || e.target);
swiper.emit('tap click', e);
if (timeDiff < 300 && touchEndTime - data.lastClickTime < 300){
swiper.emit('doubleTap doubleClick', e);
}
}

data.lastClickTime = now();
nextTick(() =>{
if (!swiper.destroyed) swiper.allowClick = true;
});

if (
!data.isTouched ||
!data.isMoved ||
!swiper.swipeDirection ||
touches.diff === 0 ||
data.currentTranslate === data.startTranslate
){
data.isTouched = false;
data.isMoved = false;
data.startMoving = false;
return;
}
data.isTouched = false;
data.isMoved = false;
data.startMoving = false;

let currentPos;
if (params.followFinger){
currentPos = rtl ? swiper.translate:-swiper.translate;
} else{
currentPos = -data.currentTranslate;
}

if (params.cssMode){
return;
}

if (swiper.params.freeMode && params.freeMode.enabled){
swiper.freeMode.onTouchEnd({ currentPos});
return;
}

// Find current slide
let stopIndex = 0;
let groupSize = swiper.slidesSizesGrid[0];
for (
let i = 0;
i < slidesGrid.length;
i += i < params.slidesPerGroupSkip ? 1:params.slidesPerGroup
){
const increment = i < params.slidesPerGroupSkip - 1 ? 1:params.slidesPerGroup;
if (typeof slidesGrid[i + increment] !== 'undefined'){
if (currentPos >= slidesGrid[i] && currentPos < slidesGrid[i + increment]){
stopIndex = i;
groupSize = slidesGrid[i + increment] - slidesGrid[i];
}
} else if (currentPos >= slidesGrid[i]){
stopIndex = i;
groupSize = slidesGrid[slidesGrid.length - 1] - slidesGrid[slidesGrid.length - 2];
}
}

let rewindFirstIndex = null;
let rewindLastIndex = null;
if (params.rewind){
if (swiper.isBeginning){
rewindLastIndex =
swiper.params.virtual && swiper.params.virtual.enabled && swiper.virtual
? swiper.virtual.slides.length - 1
:swiper.slides.length - 1;
} else if (swiper.isEnd){
rewindFirstIndex = 0;
}
}
// Find current slide size
const ratio = (currentPos - slidesGrid[stopIndex]) / groupSize;
const increment = stopIndex < params.slidesPerGroupSkip - 1 ? 1:params.slidesPerGroup;
if (timeDiff > params.longSwipesMs){
// Long touches
if (!params.longSwipes){
swiper.slideTo(swiper.activeIndex);
return;
}
if (swiper.swipeDirection === 'next'){
if (ratio >= params.longSwipesRatio)
swiper.slideTo(params.rewind && swiper.isEnd ? rewindFirstIndex:stopIndex + increment);
else swiper.slideTo(stopIndex);
}
if (swiper.swipeDirection === 'prev'){
if (ratio > 1 - params.longSwipesRatio){
swiper.slideTo(stopIndex + increment);
} else if (
rewindLastIndex !== null &&
ratio < 0 &&
Math.abs(ratio) > params.longSwipesRatio
){
swiper.slideTo(rewindLastIndex);
} else{
swiper.slideTo(stopIndex);
}
}
} else{
// Short swipes
if (!params.shortSwipes){
swiper.slideTo(swiper.activeIndex);
return;
}
const isNavButtonTarget =
swiper.navigation &&
(e.target === swiper.navigation.nextEl || e.target === swiper.navigation.prevEl);
if (!isNavButtonTarget){
if (swiper.swipeDirection === 'next'){
swiper.slideTo(rewindFirstIndex !== null ? rewindFirstIndex:stopIndex + increment);
}
if (swiper.swipeDirection === 'prev'){
swiper.slideTo(rewindLastIndex !== null ? rewindLastIndex:stopIndex);
}
} else if (e.target === swiper.navigation.nextEl){
swiper.slideTo(stopIndex + increment);
} else{
swiper.slideTo(stopIndex);
}
}
}
