export default function addSlide(index, slides){
const swiper = this;
const{ $wrapperEl, params, activeIndex} = swiper;
let activeIndexBuffer = activeIndex;
if (params.loop){
activeIndexBuffer -= swiper.loopedSlides;
swiper.loopDestroy();
swiper.slides = $wrapperEl.children(`.${params.slideClass}`);
}
const baseLength = swiper.slides.length;
if (index <= 0){
swiper.prependSlide(slides);
return;
}
if (index >= baseLength){
swiper.appendSlide(slides);
return;
}
let newActiveIndex = activeIndexBuffer > index ? activeIndexBuffer + 1:activeIndexBuffer;

const slidesBuffer = [];
for (let i = baseLength - 1; i >= index; i -= 1){
const currentSlide = swiper.slides.eq(i);
currentSlide.remove();
slidesBuffer.unshift(currentSlide);
}

if (typeof slides === 'object' && 'length' in slides){
for (let i = 0; i < slides.length; i += 1){
if (slides[i]) $wrapperEl.append(slides[i]);
}
newActiveIndex =
activeIndexBuffer > index ? activeIndexBuffer + slides.length:activeIndexBuffer;
} else{
$wrapperEl.append(slides);
}

for (let i = 0; i < slidesBuffer.length; i += 1){
$wrapperEl.append(slidesBuffer[i]);
}

if (params.loop){
swiper.loopCreate();
}
if (!params.observer){
swiper.update();
}
if (params.loop){
swiper.slideTo(newActiveIndex + swiper.loopedSlides, 0, false);
} else{
swiper.slideTo(newActiveIndex, 0, false);
}
}
