export default function updateSize(){
const swiper = this;
let width;
let height;
const $el = swiper.$el;
if (typeof swiper.params.width !== 'undefined' && swiper.params.width !== null){
width = swiper.params.width;
} else{
width = $el[0].clientWidth;
}
if (typeof swiper.params.height !== 'undefined' && swiper.params.height !== null){
height = swiper.params.height;
} else{
height = $el[0].clientHeight;
}
if ((width === 0 && swiper.isHorizontal()) || (height === 0 && swiper.isVertical())){
return;
}

// Subtract paddings
width =
width -
parseInt($el.css('padding-left') || 0, 10) -
parseInt($el.css('padding-right') || 0, 10);
height =
height -
parseInt($el.css('padding-top') || 0, 10) -
parseInt($el.css('padding-bottom') || 0, 10);

if (Number.isNaN(width)) width = 0;
if (Number.isNaN(height)) height = 0;

Object.assign(swiper,{
width,
height,
size:swiper.isHorizontal() ? width:height,
});
}
