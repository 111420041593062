export default function preloadImages(){
const swiper = this;
swiper.imagesToLoad = swiper.$el.find('img');
function onReady(){
if (typeof swiper === 'undefined' || swiper === null || !swiper || swiper.destroyed) return;
if (swiper.imagesLoaded !== undefined) swiper.imagesLoaded += 1;
if (swiper.imagesLoaded === swiper.imagesToLoad.length){
if (swiper.params.updateOnImagesReady) swiper.update();
swiper.emit('imagesReady');
}
}
for (let i = 0; i < swiper.imagesToLoad.length; i += 1){
const imageEl = swiper.imagesToLoad[i];
swiper.loadImage(
imageEl,
imageEl.currentSrc || imageEl.getAttribute('src'),
imageEl.srcset || imageEl.getAttribute('srcset'),
imageEl.sizes || imageEl.getAttribute('sizes'),
true,
onReady,
);
}
}
