import{ extend} from '../../shared/utils.js';

const isGridEnabled = (swiper, params) =>{
return swiper.grid && params.grid && params.grid.rows > 1;
};

export default function setBreakpoint(){
const swiper = this;
const{ activeIndex, initialized, loopedSlides = 0, params, $el} = swiper;
const breakpoints = params.breakpoints;
if (!breakpoints || (breakpoints && Object.keys(breakpoints).length === 0)) return;

// Get breakpoint for window width and update parameters
const breakpoint = swiper.getBreakpoint(breakpoints, swiper.params.breakpointsBase, swiper.el);

if (!breakpoint || swiper.currentBreakpoint === breakpoint) return;

const breakpointOnlyParams = breakpoint in breakpoints ? breakpoints[breakpoint]:undefined;
const breakpointParams = breakpointOnlyParams || swiper.originalParams;
const wasMultiRow = isGridEnabled(swiper, params);
const isMultiRow = isGridEnabled(swiper, breakpointParams);

const wasEnabled = params.enabled;

if (wasMultiRow && !isMultiRow){
$el.removeClass(
`${params.containerModifierClass}grid ${params.containerModifierClass}grid-column`,
);
swiper.emitContainerClasses();
} else if (!wasMultiRow && isMultiRow){
$el.addClass(`${params.containerModifierClass}grid`);
if (
(breakpointParams.grid.fill && breakpointParams.grid.fill === 'column') ||
(!breakpointParams.grid.fill && params.grid.fill === 'column')
){
$el.addClass(`${params.containerModifierClass}grid-column`);
}
swiper.emitContainerClasses();
}

// Toggle navigation, pagination, scrollbar
['navigation', 'pagination', 'scrollbar'].forEach((prop) =>{
const wasModuleEnabled = params[prop] && params[prop].enabled;
const isModuleEnabled = breakpointParams[prop] && breakpointParams[prop].enabled;
if (wasModuleEnabled && !isModuleEnabled){
swiper[prop].disable();
}
if (!wasModuleEnabled && isModuleEnabled){
swiper[prop].enable();
}
});

const directionChanged =
breakpointParams.direction && breakpointParams.direction !== params.direction;
const needsReLoop =
params.loop && (breakpointParams.slidesPerView !== params.slidesPerView || directionChanged);

if (directionChanged && initialized){
swiper.changeDirection();
}
extend(swiper.params, breakpointParams);

const isEnabled = swiper.params.enabled;

Object.assign(swiper,{
allowTouchMove:swiper.params.allowTouchMove,
allowSlideNext:swiper.params.allowSlideNext,
allowSlidePrev:swiper.params.allowSlidePrev,
});

if (wasEnabled && !isEnabled){
swiper.disable();
} else if (!wasEnabled && isEnabled){
swiper.enable();
}

swiper.currentBreakpoint = breakpoint;

swiper.emit('_beforeBreakpoint', breakpointParams);

if (needsReLoop && initialized){
swiper.loopDestroy();
swiper.loopCreate();
swiper.updateSlides();
swiper.slideTo(activeIndex - loopedSlides + swiper.loopedSlides, 0, false);
}

swiper.emit('breakpoint', breakpointParams);
}
