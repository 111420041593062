import{ getWindow} from 'ssr-window';

export default function Observer({ swiper, extendParams, on, emit}){
const observers = [];
const window = getWindow();
const attach = (target, options ={}) =>{
const ObserverFunc = window.MutationObserver || window.WebkitMutationObserver;
const observer = new ObserverFunc((mutations) =>{
// The observerUpdate event should only be triggered
// once despite the number of mutations.Additional
// triggers are redundant and are very costly
if (mutations.length === 1){
emit('observerUpdate', mutations[0]);
return;
}
const observerUpdate = function observerUpdate(){
emit('observerUpdate', mutations[0]);
};

if (window.requestAnimationFrame){
window.requestAnimationFrame(observerUpdate);
} else{
window.setTimeout(observerUpdate, 0);
}
});

observer.observe(target,{
attributes:typeof options.attributes === 'undefined' ? true:options.attributes,
childList:typeof options.childList === 'undefined' ? true:options.childList,
characterData:typeof options.characterData === 'undefined' ? true:options.characterData,
});

observers.push(observer);
};
const init = () =>{
if (!swiper.params.observer) return;
if (swiper.params.observeParents){
const containerParents = swiper.$el.parents();
for (let i = 0; i < containerParents.length; i += 1){
attach(containerParents[i]);
}
}
// Observe container
attach(swiper.$el[0],{
childList:swiper.params.observeSlideChildren,
});

// Observe wrapper
attach(swiper.$wrapperEl[0],{ attributes:false});
};
const destroy = () =>{
observers.forEach((observer) =>{
observer.disconnect();
});
observers.splice(0, observers.length);
};

extendParams({
observer:false,
observeParents:false,
observeSlideChildren:false,
});
on('init', init);
on('destroy', destroy);
}
