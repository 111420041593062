export default function prependSlide(slides){
const swiper = this;
const{ params, $wrapperEl, activeIndex} = swiper;

if (params.loop){
swiper.loopDestroy();
}
let newActiveIndex = activeIndex + 1;
if (typeof slides === 'object' && 'length' in slides){
for (let i = 0; i < slides.length; i += 1){
if (slides[i]) $wrapperEl.prepend(slides[i]);
}
newActiveIndex = activeIndex + slides.length;
} else{
$wrapperEl.prepend(slides);
}
if (params.loop){
swiper.loopCreate();
}
if (!params.observer){
swiper.update();
}
swiper.slideTo(newActiveIndex, 0, false);
}
