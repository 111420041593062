import $ from '../../shared/dom.js';
import classesToSelector from '../../shared/classes-to-selector.js';
import createElementIfNotDefined from '../../shared/create-element-if-not-defined.js';

export default function Pagination({ swiper, extendParams, on, emit}){
const pfx = 'swiper-pagination';
extendParams({
pagination:{
el:null,
bulletElement:'span',
clickable:false,
hideOnClick:false,
renderBullet:null,
renderProgressbar:null,
renderFraction:null,
renderCustom:null,
progressbarOpposite:false,
type:'bullets', // 'bullets' or 'progressbar' or 'fraction' or 'custom'
dynamicBullets:false,
dynamicMainBullets:1,
formatFractionCurrent:(number) => number,
formatFractionTotal:(number) => number,
bulletClass:`${pfx}-bullet`,
bulletActiveClass:`${pfx}-bullet-active`,
modifierClass:`${pfx}-`,
currentClass:`${pfx}-current`,
totalClass:`${pfx}-total`,
hiddenClass:`${pfx}-hidden`,
progressbarFillClass:`${pfx}-progressbar-fill`,
progressbarOppositeClass:`${pfx}-progressbar-opposite`,
clickableClass:`${pfx}-clickable`,
lockClass:`${pfx}-lock`,
horizontalClass:`${pfx}-horizontal`,
verticalClass:`${pfx}-vertical`,
paginationDisabledClass:`${pfx}-disabled`,
},
});

swiper.pagination ={
el:null,
$el:null,
bullets:[],
};

let bulletSize;
let dynamicBulletIndex = 0;

function isPaginationDisabled(){
return (
!swiper.params.pagination.el ||
!swiper.pagination.el ||
!swiper.pagination.$el ||
swiper.pagination.$el.length === 0
);
}

function setSideBullets($bulletEl, position){
const{ bulletActiveClass} = swiper.params.pagination;
$bulletEl[position]()
.addClass(`${bulletActiveClass}-${position}`)
[position]()
.addClass(`${bulletActiveClass}-${position}-${position}`);
}

function update(){
// Render || Update Pagination bullets/items
const rtl = swiper.rtl;
const params = swiper.params.pagination;
if (isPaginationDisabled()) return;
const slidesLength =
swiper.virtual && swiper.params.virtual.enabled
? swiper.virtual.slides.length
:swiper.slides.length;
const $el = swiper.pagination.$el;
// Current/Total
let current;
const total = swiper.params.loop
? Math.ceil((slidesLength - swiper.loopedSlides * 2) / swiper.params.slidesPerGroup)
:swiper.snapGrid.length;
if (swiper.params.loop){
current = Math.ceil(
(swiper.activeIndex - swiper.loopedSlides) / swiper.params.slidesPerGroup,
);
if (current > slidesLength - 1 - swiper.loopedSlides * 2){
current -= slidesLength - swiper.loopedSlides * 2;
}
if (current > total - 1) current -= total;
if (current < 0 && swiper.params.paginationType !== 'bullets') current = total + current;
} else if (typeof swiper.snapIndex !== 'undefined'){
current = swiper.snapIndex;
} else{
current = swiper.activeIndex || 0;
}
// Types
if (
params.type === 'bullets' &&
swiper.pagination.bullets &&
swiper.pagination.bullets.length > 0
){
const bullets = swiper.pagination.bullets;
let firstIndex;
let lastIndex;
let midIndex;
if (params.dynamicBullets){
bulletSize = bullets.eq(0)[swiper.isHorizontal() ? 'outerWidth':'outerHeight'](true);
$el.css(
swiper.isHorizontal() ? 'width':'height',
`${bulletSize * (params.dynamicMainBullets + 4)}px`,
);
if (params.dynamicMainBullets > 1 && swiper.previousIndex !== undefined){
dynamicBulletIndex += current - (swiper.previousIndex - swiper.loopedSlides || 0);
if (dynamicBulletIndex > params.dynamicMainBullets - 1){
dynamicBulletIndex = params.dynamicMainBullets - 1;
} else if (dynamicBulletIndex < 0){
dynamicBulletIndex = 0;
}
}
firstIndex = Math.max(current - dynamicBulletIndex, 0);
lastIndex = firstIndex + (Math.min(bullets.length, params.dynamicMainBullets) - 1);
midIndex = (lastIndex + firstIndex) / 2;
}
bullets.removeClass(
['', '-next', '-next-next', '-prev', '-prev-prev', '-main']
.map((suffix) => `${params.bulletActiveClass}${suffix}`)
.join(' '),
);
if ($el.length > 1){
bullets.each((bullet) =>{
const $bullet = $(bullet);
const bulletIndex = $bullet.index();
if (bulletIndex === current){
$bullet.addClass(params.bulletActiveClass);
}
if (params.dynamicBullets){
if (bulletIndex >= firstIndex && bulletIndex <= lastIndex){
$bullet.addClass(`${params.bulletActiveClass}-main`);
}
if (bulletIndex === firstIndex){
setSideBullets($bullet, 'prev');
}
if (bulletIndex === lastIndex){
setSideBullets($bullet, 'next');
}
}
});
} else{
const $bullet = bullets.eq(current);
const bulletIndex = $bullet.index();
$bullet.addClass(params.bulletActiveClass);
if (params.dynamicBullets){
const $firstDisplayedBullet = bullets.eq(firstIndex);
const $lastDisplayedBullet = bullets.eq(lastIndex);
for (let i = firstIndex; i <= lastIndex; i += 1){
bullets.eq(i).addClass(`${params.bulletActiveClass}-main`);
}
if (swiper.params.loop){
if (bulletIndex >= bullets.length){
for (let i = params.dynamicMainBullets; i >= 0; i -= 1){
bullets.eq(bullets.length - i).addClass(`${params.bulletActiveClass}-main`);
}
bullets
.eq(bullets.length - params.dynamicMainBullets - 1)
.addClass(`${params.bulletActiveClass}-prev`);
} else{
setSideBullets($firstDisplayedBullet, 'prev');
setSideBullets($lastDisplayedBullet, 'next');
}
} else{
setSideBullets($firstDisplayedBullet, 'prev');
setSideBullets($lastDisplayedBullet, 'next');
}
}
}
if (params.dynamicBullets){
const dynamicBulletsLength = Math.min(bullets.length, params.dynamicMainBullets + 4);
const bulletsOffset =
(bulletSize * dynamicBulletsLength - bulletSize) / 2 - midIndex * bulletSize;
const offsetProp = rtl ? 'right':'left';
bullets.css(swiper.isHorizontal() ? offsetProp:'top', `${bulletsOffset}px`);
}
}
if (params.type === 'fraction'){
$el
.find(classesToSelector(params.currentClass))
.text(params.formatFractionCurrent(current + 1));
$el.find(classesToSelector(params.totalClass)).text(params.formatFractionTotal(total));
}
if (params.type === 'progressbar'){
let progressbarDirection;
if (params.progressbarOpposite){
progressbarDirection = swiper.isHorizontal() ? 'vertical':'horizontal';
} else{
progressbarDirection = swiper.isHorizontal() ? 'horizontal':'vertical';
}
const scale = (current + 1) / total;
let scaleX = 1;
let scaleY = 1;
if (progressbarDirection === 'horizontal'){
scaleX = scale;
} else{
scaleY = scale;
}
$el
.find(classesToSelector(params.progressbarFillClass))
.transform(`translate3d(0,0,0) scaleX(${scaleX}) scaleY(${scaleY})`)
.transition(swiper.params.speed);
}
if (params.type === 'custom' && params.renderCustom){
$el.html(params.renderCustom(swiper, current + 1, total));
emit('paginationRender', $el[0]);
} else{
emit('paginationUpdate', $el[0]);
}
if (swiper.params.watchOverflow && swiper.enabled){
$el[swiper.isLocked ? 'addClass':'removeClass'](params.lockClass);
}
}
function render(){
// Render Container
const params = swiper.params.pagination;
if (isPaginationDisabled()) return;
const slidesLength =
swiper.virtual && swiper.params.virtual.enabled
? swiper.virtual.slides.length
:swiper.slides.length;

const $el = swiper.pagination.$el;
let paginationHTML = '';
if (params.type === 'bullets'){
let numberOfBullets = swiper.params.loop
? Math.ceil((slidesLength - swiper.loopedSlides * 2) / swiper.params.slidesPerGroup)
:swiper.snapGrid.length;
if (
swiper.params.freeMode &&
swiper.params.freeMode.enabled &&
!swiper.params.loop &&
numberOfBullets > slidesLength
){
numberOfBullets = slidesLength;
}
for (let i = 0; i < numberOfBullets; i += 1){
if (params.renderBullet){
paginationHTML += params.renderBullet.call(swiper, i, params.bulletClass);
} else{
paginationHTML += `<${params.bulletElement} class="${params.bulletClass}"></${params.bulletElement}>`;
}
}
$el.html(paginationHTML);

swiper.pagination.bullets = $el.find(classesToSelector(params.bulletClass));
}
if (params.type === 'fraction'){
if (params.renderFraction){
paginationHTML = params.renderFraction.call(swiper, params.currentClass, params.totalClass);
} else{
paginationHTML =
`<span class="${params.currentClass}"></span>` +
' / ' +
`<span class="${params.totalClass}"></span>`;
}
$el.html(paginationHTML);
}
if (params.type === 'progressbar'){
if (params.renderProgressbar){
paginationHTML = params.renderProgressbar.call(swiper, params.progressbarFillClass);
} else{
paginationHTML = `<span class="${params.progressbarFillClass}"></span>`;
}
$el.html(paginationHTML);
}
if (params.type !== 'custom'){
emit('paginationRender', swiper.pagination.$el[0]);
}
}
function init(){
swiper.params.pagination = createElementIfNotDefined(
swiper,
swiper.originalParams.pagination,
swiper.params.pagination,
{ el:'swiper-pagination'},
);
const params = swiper.params.pagination;
if (!params.el) return;

let $el = $(params.el);
if ($el.length === 0) return;

if (swiper.params.uniqueNavElements && typeof params.el === 'string' && $el.length > 1){
$el = swiper.$el.find(params.el);
// check if it belongs to another nested Swiper
if ($el.length > 1){
$el = $el.filter((el) =>{
if ($(el).parents('.swiper')[0] !== swiper.el) return false;
return true;
});
}
}

if (params.type === 'bullets' && params.clickable){
$el.addClass(params.clickableClass);
}

$el.addClass(params.modifierClass + params.type);
$el.addClass(swiper.isHorizontal() ? params.horizontalClass:params.verticalClass);

if (params.type === 'bullets' && params.dynamicBullets){
$el.addClass(`${params.modifierClass}${params.type}-dynamic`);
dynamicBulletIndex = 0;
if (params.dynamicMainBullets < 1){
params.dynamicMainBullets = 1;
}
}
if (params.type === 'progressbar' && params.progressbarOpposite){
$el.addClass(params.progressbarOppositeClass);
}

if (params.clickable){
$el.on('click', classesToSelector(params.bulletClass), function onClick(e){
e.preventDefault();
let index = $(this).index() * swiper.params.slidesPerGroup;
if (swiper.params.loop) index += swiper.loopedSlides;
swiper.slideTo(index);
});
}

Object.assign(swiper.pagination,{
$el,
el:$el[0],
});

if (!swiper.enabled){
$el.addClass(params.lockClass);
}
}
function destroy(){
const params = swiper.params.pagination;
if (isPaginationDisabled()) return;
const $el = swiper.pagination.$el;

$el.removeClass(params.hiddenClass);
$el.removeClass(params.modifierClass + params.type);
$el.removeClass(swiper.isHorizontal() ? params.horizontalClass:params.verticalClass);
if (swiper.pagination.bullets && swiper.pagination.bullets.removeClass)
swiper.pagination.bullets.removeClass(params.bulletActiveClass);
if (params.clickable){
$el.off('click', classesToSelector(params.bulletClass));
}
}

on('init', () =>{
if (swiper.params.pagination.enabled === false){
// eslint-disable-next-line
disable();
} else{
init();
render();
update();
}
});
on('activeIndexChange', () =>{
if (swiper.params.loop){
update();
} else if (typeof swiper.snapIndex === 'undefined'){
update();
}
});
on('snapIndexChange', () =>{
if (!swiper.params.loop){
update();
}
});
on('slidesLengthChange', () =>{
if (swiper.params.loop){
render();
update();
}
});
on('snapGridLengthChange', () =>{
if (!swiper.params.loop){
render();
update();
}
});
on('destroy', () =>{
destroy();
});
on('enable disable', () =>{
const{ $el} = swiper.pagination;
if ($el){
$el[swiper.enabled ? 'removeClass':'addClass'](swiper.params.pagination.lockClass);
}
});
on('lock unlock', () =>{
update();
});
on('click', (_s, e) =>{
const targetEl = e.target;
const{ $el} = swiper.pagination;
if (
swiper.params.pagination.el &&
swiper.params.pagination.hideOnClick &&
$el &&
$el.length > 0 &&
!$(targetEl).hasClass(swiper.params.pagination.bulletClass)
){
if (
swiper.navigation &&
((swiper.navigation.nextEl && targetEl === swiper.navigation.nextEl) ||
(swiper.navigation.prevEl && targetEl === swiper.navigation.prevEl))
)
return;
const isHidden = $el.hasClass(swiper.params.pagination.hiddenClass);
if (isHidden === true){
emit('paginationShow');
} else{
emit('paginationHide');
}
$el.toggleClass(swiper.params.pagination.hiddenClass);
}
});

const enable = () =>{
swiper.$el.removeClass(swiper.params.pagination.paginationDisabledClass);
if (swiper.pagination.$el){
swiper.pagination.$el.removeClass(swiper.params.pagination.paginationDisabledClass);
}
init();
render();
update();
};

const disable = () =>{
swiper.$el.addClass(swiper.params.pagination.paginationDisabledClass);
if (swiper.pagination.$el){
swiper.pagination.$el.addClass(swiper.params.pagination.paginationDisabledClass);
}
destroy();
};

Object.assign(swiper.pagination,{
enable,
disable,
render,
update,
init,
destroy,
});
}
