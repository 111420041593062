export default function onResize(){
const swiper = this;

const{ params, el} = swiper;

if (el && el.offsetWidth === 0) return;

// Breakpoints
if (params.breakpoints){
swiper.setBreakpoint();
}

// Save locks
const{ allowSlideNext, allowSlidePrev, snapGrid} = swiper;

// Disable locks on resize
swiper.allowSlideNext = true;
swiper.allowSlidePrev = true;

swiper.updateSize();
swiper.updateSlides();

swiper.updateSlidesClasses();
if (
(params.slidesPerView === 'auto' || params.slidesPerView > 1) &&
swiper.isEnd &&
!swiper.isBeginning &&
!swiper.params.centeredSlides
){
swiper.slideTo(swiper.slides.length - 1, 0, false, true);
} else{
swiper.slideTo(swiper.activeIndex, 0, false, true);
}

if (swiper.autoplay && swiper.autoplay.running && swiper.autoplay.paused){
swiper.autoplay.run();
}
// Return locks after resize
swiper.allowSlidePrev = allowSlidePrev;
swiper.allowSlideNext = allowSlideNext;

if (swiper.params.watchOverflow && snapGrid !== swiper.snapGrid){
swiper.checkOverflow();
}
}
