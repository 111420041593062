export default{
init:true,
direction:'horizontal',
touchEventsTarget:'wrapper',
initialSlide:0,
speed:300,
cssMode:false,
updateOnWindowResize:true,
resizeObserver:true,
nested:false,
createElements:false,
enabled:true,
focusableElements:'input, select, option, textarea, button, video, label',

// Overrides
width:null,
height:null,

//
preventInteractionOnTransition:false,

// ssr
userAgent:null,
url:null,

// To support iOS's swipe-to-go-back gesture (when being used in-app).
edgeSwipeDetection:false,
edgeSwipeThreshold:20,

// Autoheight
autoHeight:false,

// Set wrapper width
setWrapperSize:false,

// Virtual Translate
virtualTranslate:false,

// Effects
effect:'slide', // 'slide' or 'fade' or 'cube' or 'coverflow' or 'flip'

// Breakpoints
breakpoints:undefined,
breakpointsBase:'window',

// Slides grid
spaceBetween:0,
slidesPerView:1,
slidesPerGroup:1,
slidesPerGroupSkip:0,
slidesPerGroupAuto:false,
centeredSlides:false,
centeredSlidesBounds:false,
slidesOffsetBefore:0, // in px
slidesOffsetAfter:0, // in px
normalizeSlideIndex:true,
centerInsufficientSlides:false,

// Disable swiper and hide navigation when container not overflow
watchOverflow:true,

// Round length
roundLengths:false,

// Touches
touchRatio:1,
touchAngle:45,
simulateTouch:true,
shortSwipes:true,
longSwipes:true,
longSwipesRatio:0.5,
longSwipesMs:300,
followFinger:true,
allowTouchMove:true,
threshold:0,
touchMoveStopPropagation:false,
touchStartPreventDefault:true,
touchStartForcePreventDefault:false,
touchReleaseOnEdges:false,

// Unique Navigation Elements
uniqueNavElements:true,

// Resistance
resistance:true,
resistanceRatio:0.85,

// Progress
watchSlidesProgress:false,

// Cursor
grabCursor:false,

// Clicks
preventClicks:true,
preventClicksPropagation:true,
slideToClickedSlide:false,

// Images
preloadImages:true,
updateOnImagesReady:true,

// loop
loop:false,
loopAdditionalSlides:0,
loopedSlides:null,
loopedSlidesLimit:true,
loopFillGroupWithBlank:false,
loopPreventsSlide:true,

// rewind
rewind:false,

// Swiping/no swiping
allowSlidePrev:true,
allowSlideNext:true,
swipeHandler:null, // '.swipe-handler',
noSwiping:true,
noSwipingClass:'swiper-no-swiping',
noSwipingSelector:null,

// Passive Listeners
passiveListeners:true,

maxBackfaceHiddenSlides:10,

// NS
containerModifierClass:'swiper-', // NEW
slideClass:'swiper-slide',
slideBlankClass:'swiper-slide-invisible-blank',
slideActiveClass:'swiper-slide-active',
slideDuplicateActiveClass:'swiper-slide-duplicate-active',
slideVisibleClass:'swiper-slide-visible',
slideDuplicateClass:'swiper-slide-duplicate',
slideNextClass:'swiper-slide-next',
slideDuplicateNextClass:'swiper-slide-duplicate-next',
slidePrevClass:'swiper-slide-prev',
slideDuplicatePrevClass:'swiper-slide-duplicate-prev',
wrapperClass:'swiper-wrapper',

// Callbacks
runCallbacksOnInit:true,

// Internals
_emitClasses:false,
};
