import createElementIfNotDefined from '../../shared/create-element-if-not-defined.js';
import $ from '../../shared/dom.js';

export default function Navigation({ swiper, extendParams, on, emit}){
extendParams({
navigation:{
nextEl:null,
prevEl:null,

hideOnClick:false,
disabledClass:'swiper-button-disabled',
hiddenClass:'swiper-button-hidden',
lockClass:'swiper-button-lock',
navigationDisabledClass:'swiper-navigation-disabled',
},
});

swiper.navigation ={
nextEl:null,
$nextEl:null,
prevEl:null,
$prevEl:null,
};

function getEl(el){
let $el;
if (el){
$el = $(el);
if (
swiper.params.uniqueNavElements &&
typeof el === 'string' &&
$el.length > 1 &&
swiper.$el.find(el).length === 1
){
$el = swiper.$el.find(el);
}
}
return $el;
}

function toggleEl($el, disabled){
const params = swiper.params.navigation;
if ($el && $el.length > 0){
$el[disabled ? 'addClass':'removeClass'](params.disabledClass);
if ($el[0] && $el[0].tagName === 'BUTTON') $el[0].disabled = disabled;
if (swiper.params.watchOverflow && swiper.enabled){
$el[swiper.isLocked ? 'addClass':'removeClass'](params.lockClass);
}
}
}
function update(){
// Update Navigation Buttons
if (swiper.params.loop) return;
const{ $nextEl, $prevEl} = swiper.navigation;

toggleEl($prevEl, swiper.isBeginning && !swiper.params.rewind);
toggleEl($nextEl, swiper.isEnd && !swiper.params.rewind);
}
function onPrevClick(e){
e.preventDefault();
if (swiper.isBeginning && !swiper.params.loop && !swiper.params.rewind) return;
swiper.slidePrev();
emit('navigationPrev');
}
function onNextClick(e){
e.preventDefault();
if (swiper.isEnd && !swiper.params.loop && !swiper.params.rewind) return;
swiper.slideNext();
emit('navigationNext');
}
function init(){
const params = swiper.params.navigation;

swiper.params.navigation = createElementIfNotDefined(
swiper,
swiper.originalParams.navigation,
swiper.params.navigation,
{
nextEl:'swiper-button-next',
prevEl:'swiper-button-prev',
},
);
if (!(params.nextEl || params.prevEl)) return;

const $nextEl = getEl(params.nextEl);
const $prevEl = getEl(params.prevEl);

if ($nextEl && $nextEl.length > 0){
$nextEl.on('click', onNextClick);
}
if ($prevEl && $prevEl.length > 0){
$prevEl.on('click', onPrevClick);
}

Object.assign(swiper.navigation,{
$nextEl,
nextEl:$nextEl && $nextEl[0],
$prevEl,
prevEl:$prevEl && $prevEl[0],
});

if (!swiper.enabled){
if ($nextEl) $nextEl.addClass(params.lockClass);
if ($prevEl) $prevEl.addClass(params.lockClass);
}
}
function destroy(){
const{ $nextEl, $prevEl} = swiper.navigation;
if ($nextEl && $nextEl.length){
$nextEl.off('click', onNextClick);
$nextEl.removeClass(swiper.params.navigation.disabledClass);
}
if ($prevEl && $prevEl.length){
$prevEl.off('click', onPrevClick);
$prevEl.removeClass(swiper.params.navigation.disabledClass);
}
}

on('init', () =>{
if (swiper.params.navigation.enabled === false){
// eslint-disable-next-line
disable();
} else{
init();
update();
}
});
on('toEdge fromEdge lock unlock', () =>{
update();
});
on('destroy', () =>{
destroy();
});
on('enable disable', () =>{
const{ $nextEl, $prevEl} = swiper.navigation;
if ($nextEl){
$nextEl[swiper.enabled ? 'removeClass':'addClass'](swiper.params.navigation.lockClass);
}
if ($prevEl){
$prevEl[swiper.enabled ? 'removeClass':'addClass'](swiper.params.navigation.lockClass);
}
});
on('click', (_s, e) =>{
const{ $nextEl, $prevEl} = swiper.navigation;
const targetEl = e.target;
if (
swiper.params.navigation.hideOnClick &&
!$(targetEl).is($prevEl) &&
!$(targetEl).is($nextEl)
){
if (
swiper.pagination &&
swiper.params.pagination &&
swiper.params.pagination.clickable &&
(swiper.pagination.el === targetEl || swiper.pagination.el.contains(targetEl))
)
return;
let isHidden;
if ($nextEl){
isHidden = $nextEl.hasClass(swiper.params.navigation.hiddenClass);
} else if ($prevEl){
isHidden = $prevEl.hasClass(swiper.params.navigation.hiddenClass);
}
if (isHidden === true){
emit('navigationShow');
} else{
emit('navigationHide');
}
if ($nextEl){
$nextEl.toggleClass(swiper.params.navigation.hiddenClass);
}
if ($prevEl){
$prevEl.toggleClass(swiper.params.navigation.hiddenClass);
}
}
});

const enable = () =>{
swiper.$el.removeClass(swiper.params.navigation.navigationDisabledClass);
init();
update();
};

const disable = () =>{
swiper.$el.addClass(swiper.params.navigation.navigationDisabledClass);
destroy();
};

Object.assign(swiper.navigation,{
enable,
disable,
update,
init,
destroy,
});
}
