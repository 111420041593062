export default function appendSlide(slides){
const swiper = this;
const{ $wrapperEl, params} = swiper;
if (params.loop){
swiper.loopDestroy();
}
if (typeof slides === 'object' && 'length' in slides){
for (let i = 0; i < slides.length; i += 1){
if (slides[i]) $wrapperEl.append(slides[i]);
}
} else{
$wrapperEl.append(slides);
}
if (params.loop){
swiper.loopCreate();
}
if (!params.observer){
swiper.update();
}
}
