export default function onScroll(){
const swiper = this;
const{ wrapperEl, rtlTranslate, enabled} = swiper;
if (!enabled) return;
swiper.previousTranslate = swiper.translate;
if (swiper.isHorizontal()){
swiper.translate = -wrapperEl.scrollLeft;
} else{
swiper.translate = -wrapperEl.scrollTop;
}
// eslint-disable-next-line
if (swiper.translate === 0) swiper.translate = 0;

swiper.updateActiveIndex();
swiper.updateSlidesClasses();

let newProgress;
const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
if (translatesDiff === 0){
newProgress = 0;
} else{
newProgress = (swiper.translate - swiper.minTranslate()) / translatesDiff;
}
if (newProgress !== swiper.progress){
swiper.updateProgress(rtlTranslate ? -swiper.translate:swiper.translate);
}

swiper.emit('setTranslate', swiper.translate, false);
}
