export default function updateProgress(translate){
const swiper = this;
if (typeof translate === 'undefined'){
const multiplier = swiper.rtlTranslate ? -1:1;
// eslint-disable-next-line
translate = (swiper && swiper.translate && swiper.translate * multiplier) || 0;
}
const params = swiper.params;
const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
let{ progress, isBeginning, isEnd} = swiper;
const wasBeginning = isBeginning;
const wasEnd = isEnd;
if (translatesDiff === 0){
progress = 0;
isBeginning = true;
isEnd = true;
} else{
progress = (translate - swiper.minTranslate()) / translatesDiff;
isBeginning = progress <= 0;
isEnd = progress >= 1;
}
Object.assign(swiper,{
progress,
isBeginning,
isEnd,
});

if (params.watchSlidesProgress || (params.centeredSlides && params.autoHeight))
swiper.updateSlidesProgress(translate);

if (isBeginning && !wasBeginning){
swiper.emit('reachBeginning toEdge');
}
if (isEnd && !wasEnd){
swiper.emit('reachEnd toEdge');
}
if ((wasBeginning && !isBeginning) || (wasEnd && !isEnd)){
swiper.emit('fromEdge');
}

swiper.emit('progress', progress);
}
