import{ getWindow} from 'ssr-window';

export default function Resize({ swiper, on, emit}){
const window = getWindow();
let observer = null;
let animationFrame = null;

const resizeHandler = () =>{
if (!swiper || swiper.destroyed || !swiper.initialized) return;
emit('beforeResize');
emit('resize');
};

const createObserver = () =>{
if (!swiper || swiper.destroyed || !swiper.initialized) return;
observer = new ResizeObserver((entries) =>{
animationFrame = window.requestAnimationFrame(() =>{
const{ width, height} = swiper;
let newWidth = width;
let newHeight = height;
entries.forEach(({ contentBoxSize, contentRect, target}) =>{
if (target && target !== swiper.el) return;
newWidth = contentRect
? contentRect.width
:(contentBoxSize[0] || contentBoxSize).inlineSize;
newHeight = contentRect
? contentRect.height
:(contentBoxSize[0] || contentBoxSize).blockSize;
});
if (newWidth !== width || newHeight !== height){
resizeHandler();
}
});
});
observer.observe(swiper.el);
};

const removeObserver = () =>{
if (animationFrame){
window.cancelAnimationFrame(animationFrame);
}
if (observer && observer.unobserve && swiper.el){
observer.unobserve(swiper.el);
observer = null;
}
};

const orientationChangeHandler = () =>{
if (!swiper || swiper.destroyed || !swiper.initialized) return;
emit('orientationchange');
};

on('init', () =>{
if (swiper.params.resizeObserver && typeof window.ResizeObserver !== 'undefined'){
createObserver();
return;
}
window.addEventListener('resize', resizeHandler);
window.addEventListener('orientationchange', orientationChangeHandler);
});

on('destroy', () =>{
removeObserver();
window.removeEventListener('resize', resizeHandler);
window.removeEventListener('orientationchange', orientationChangeHandler);
});
}
