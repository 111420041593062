export default function setTranslate(translate, byController){
const swiper = this;
const{ rtlTranslate:rtl, params, $wrapperEl, wrapperEl, progress} = swiper;
let x = 0;
let y = 0;
const z = 0;

if (swiper.isHorizontal()){
x = rtl ? -translate:translate;
} else{
y = translate;
}

if (params.roundLengths){
x = Math.floor(x);
y = Math.floor(y);
}

if (params.cssMode){
wrapperEl[swiper.isHorizontal() ? 'scrollLeft':'scrollTop'] = swiper.isHorizontal() ? -x:-y;
} else if (!params.virtualTranslate){
$wrapperEl.transform(`translate3d(${x}px, ${y}px, ${z}px)`);
}
swiper.previousTranslate = swiper.translate;
swiper.translate = swiper.isHorizontal() ? x:y;

// Check if we need to update progress
let newProgress;
const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
if (translatesDiff === 0){
newProgress = 0;
} else{
newProgress = (translate - swiper.minTranslate()) / translatesDiff;
}
if (newProgress !== progress){
swiper.updateProgress(translate);
}

swiper.emit('setTranslate', swiper.translate, byController);
}
