import{ getDocument} from 'ssr-window';

export default function createElementIfNotDefined(swiper, originalParams, params, checkProps){
const document = getDocument();
if (swiper.params.createElements){
Object.keys(checkProps).forEach((key) =>{
if (!params[key] && params.auto === true){
let element = swiper.$el.children(`.${checkProps[key]}`)[0];
if (!element){
element = document.createElement('div');
element.className = checkProps[key];
swiper.$el.append(element);
}
params[key] = element;
originalParams[key] = element;
}
});
}
return params;
}
